.App {
    text-align: center;
    background-color: #ffbead;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @font-face {
    font-family: "IBMPlexMono";
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-SemiBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "IBMPlexMono";
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf') format('truetype');
    font-weight: light;
    font-style: normal;
  }

  @font-face {
    font-family: "Inter";
    src: url('./assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Inter";
    src: url('./assets/fonts/Inter/static/Inter-Light.ttf') format('truetype');
    font-weight: light;
    font-style: normal;
  }

  @font-face {
    font-family: "Inter";
    src: url('./assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .cursor-icon {
    position: fixed;
    pointer-events: none;
    z-index: 9999;
    display: none;
  }
  
  .icon-hover:hover .cursor-icon {
    display: block;
  }
  
  .copied{
    position: fixed;
    z-index: 9999;
    font-size: 10px;
    color: #0f114f;
  }
  
  .show-copied {
    display: block;
  }
  

  .homeButton {
    position: fixed;
    color: white;
    top: 20px;
    left: 20px;
    z-index: 300;
  }

  .highlightOverlay {
    position: fixed;
    top: 94vh;
    left: -120vw;
    z-index: 300;
    width: 100%;
    transition: left 0.3s ease-in-out;
  }

  .highlightOverlay.visible {
    left: 0vw;
    top: 94vh;
  }
  
  .highlightIcons {
    position: absolute;
    z-index: 600;
    display: flex;
    justify-content: left;
    text-align: left;
    margin-left: 3vh;
    color: #0f114f;
    gap: 2vh;
  }

  .emailText {
    font-family: "Inter", sans-serif;
    font-size: 2vh;
    margin-top: 0.5vh;
  }

  .highlightOverlay.visible .emailText:hover {
    cursor: pointer;
  }
  
  .mainPage {
    background-color: #ffbead;
    font-family: "IBMPlexMono", sans-serif;
    height: 100vh;
    transition: transform 0.3s ease;
  }

  .flyAway {
    background-color: #ffbead;
    font-family: "IBMPlexMono", sans-serif;
    animation: flyToTop 2s forwards;
  }
  
  .homePageRows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    gap: 20px;
  }
  
  .infoBubble {
    background-color: #FFFFFF;
    max-width: 500px;
    min-width: 300px;
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: light;
    border-radius: 15px;
    border: 1px solid #ff7f5f;
  }
  
  .infoTitle {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .infoBody {
    font-size: 15px;
    margin-top: 10px;
    font-weight: light;
    overflow-wrap: 'break-word';
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .pageIcon {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  
  .homePageRow1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }

  .homePageRow2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    gap: 20px;
  }

  .homePageRow4 {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: bounce 2s infinite;
  }
  
  .homePageRows img {
    width: 96px;
    height: 96px;
    image-rendering: pixelated;
    transition: transform 1s ease;
    cursor: pointer;
  }

  .homePageRows img:not(:hover) {
    animation: iconBounce 3s infinite;
  }
  
  .homePageRows img:hover {
    transform: scale(1.2);
  }

  .upsideDown {
    transform: rotate(180deg);
  }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
  }
  
  .circle {
    border: 2000px solid black;
    background-color: transparent;
    border-radius: 50%;
    width: 150%;
    padding-bottom: 150%;
    position: absolute;
    background-size: cover;
    background-position: center;
    animation: shrink 1s forwards;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(10);
    }
    50% {
      transform: translateY(-8px);
    }
  }
  
  @keyframes shrink {
    0% {
        width: 150%;
        padding-bottom: 150%;
    }
    40% {
        width: 100px;
        padding-bottom: 100px;
    }
    67% {
      width: 100px;
      padding-bottom: 100px;
    }
    75% {
      width: 120px;
      padding-bottom: 120px;
    }
    100% {
        width: 0%;
        padding-bottom: 0%;
    }
  }

  @keyframes flyToTop {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-200vh);
    }
    90% {
      transform: translateY(-200vh);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes iconBounce {
    0%, 100% {
      transform: translateY(0) rotate(0deg);
    }
    10% {
      transform: translateY(0) rotate(0deg);
    }
    20% {
      transform: translateY(0) rotate(5deg);
    }
    30% {
      transform: translateY(0) rotate(0deg);
    }
    40% {
      transform: translateY(-2px) rotate(0deg);
    }
    50% {
      transform: translateY(0) rotate(-5deg);
    }
    60% {
      transform: translateY(-2px) rotate(0deg);
    }
    70% {
      transform: translateY(0) rotate(0deg);
    }
    80% {
      transform: translateY(-2px) rotate(0deg);
    }
    90% {
      transform: translateY(0) rotate(0deg);
    }
  }

  .highlights1 {
    background-color: #ffbead;
    height: 100vh;
  }

  .mePhotoBox {
    display: flex;
    top: 21vh;
    position: relative;
    z-index: 1;
    width: 100%;
    justify-content: center;
  }

  .mePhoto {
    border-radius: 15px;
    border: 1px solid #ff7f5f;
    width: 280px;
    height: 350px;
    transition: transform 0.2s ease;
  }

  .bigText {
    position: absolute;
    font-family: "Inter", sans-serif;
    top: -140px;
    margin-left: -220px;
    height: 100vh;
    z-index: 100;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 1px #ff7f5f;
    font-size: 90px;
    line-height: 1;
    transition: transform 0.3s ease;
  }

  .descOverlay {
    position: absolute;
    top: 126vh;
    z-index: 200;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: transform 0.3s ease;
  }

  @media (max-width: 700px) {
    .descOverlay {
      transform: scale(0.5);
    }
    .bigText {
      top: -125px;
    }
    .mePhoto {
      transform: scale(0.5);
      margin-top: 3.5vh;
    }
  }
  
  .descColumn {
    display: flex;
    flex-direction: column;
  }

  .descItem {
    display: flex;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    font-weight: normal;
    font-size: 15px;
    background-color: white;
    border: 1px solid #ff7f5f;
    border-radius: 9px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 7.2px;
    padding-right: 7.2px;
    
  }

  .descItem1 {
    margin-top: 70px;
    margin-right: 70px;
    margin-left: -60px;
    width: 228px;
  }

  .descItem2 {
    margin-top: 42px;
    margin-left: 6px;
    width: 138px;
  }

  .descItem3 {
    margin-top: 42px;
    margin-left: 72px;
    width: 132px;
  }

  .descItem4 {
    margin-top: -18px;
    margin-left: 90px;
    width: 174px;
  }

  .descItem5 {
    margin-top: 30px;
    margin-left: 140px;
    width: 168px;
  }

  .descItem6 {
    margin-top: 30px;
    margin-left: 125px;
    width: 156px;
  }

  .descItem7 {
    width: 128px;
    height: 128px;
    image-rendering: pixelated;
    margin-left: 105px;
  }

  .centerArrow {
    position: absolute;
    top: 190vh;
    z-index: 300;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .downArrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: bounce 2s infinite;
    color: white;
    -webkit-text-stroke: 1px #ff7f5f;
    font-family: "IBMPlexMono", sans-serif;
    font-weight: bold;
    font-size: 5vh;
    z-index: 500;
  }

  .highlights2 {
    background-color: #ffbead;
    height: 100vh;
  }

  .highlights2TopBox {
    top: 210vh;
    height: 25%;
  }

  .highlights2TopLeft {
    margin-top: 4vh;
    margin-left: 5vw;
    position: absolute;
    font-family: "Inter", sans-serif;
    width: 50%;
    z-index: 100;
    font-weight: bold;
    color: white;
    font-size: 5vw;
    line-height: 1;
    text-shadow: 
      -0.1vh -0.1vh 0 #ff7f5f,  
      0.1vh -0.1vh 0 #ff7f5f,
      -0.1vh 0.1vh 0 #ff7f5f,
      0.1vh 0.1vh 0 #ff7f5f,
      0 -0.15vh 0 #ff7f5f,
      0 0.15vh 0 #ff7f5f;
  }

  .highlights2TopRight {
    position: absolute;
    left: 50vw;
    margin-top: 4vh;
    font-family: "Inter", sans-serif;
    width: 45%;
    z-index: 100;
    font-weight: bold;
    color: white;
    font-size: 2.2vw;
    line-height: 1.5;
    text-shadow: 
      -0.1vh -0.1vh 0 #ff7f5f,  
      0.1vh -0.1vh 0 #ff7f5f,
      -0.1vh 0.1vh 0 #ff7f5f,
      0.1vh 0.1vh 0 #ff7f5f,
      0 -0.15vh 0 #ff7f5f,
      0 0.15vh 0 #ff7f5f;
  }
  

  .timeline {
    position: absolute;
    top: 225vh;
    left: -1500px;
    z-index: 300;
    width: 100%;
    transition: left 1s ease-in-out;
    height: 60vh;
  }

  .timeline.visible {
    left: 0vw;
  }

  @media screen and (orientation: portrait) {
    .timeline {
      overflow-y: hidden;
    }
  }
  

  .centerArrow2 {
    position: absolute;
    top: 290vh;
    z-index: 300;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .rightArrow {
    position: fixed;
    top: 268vh;
    left: 85vw;
    z-index: 300;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: rightBounce 2s infinite;
    color: white;
    -webkit-text-stroke: 1px #ff7f5f;
    font-family: "IBMPlexMono", sans-serif;
    font-weight: bold;
    font-size: 8vw;
    z-index: 500;
  }

  .right {
    transform: rotate(90deg);
  }

  @keyframes rightBounce {
    0%, 100% {
      transform: translateX(10);
    }
    50% {
      transform: translateX(-8px);
    }
  }

  .highlights3 {
    background-color: #ffbead;
    height: 100vh;
  }